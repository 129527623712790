import React, { Component } from 'react';
import QrReader from 'react-qr-reader';

class QrCamera extends Component {
  constructor(props) {
    super(props);

    this.state = { result: 'No result', name: '', server: undefined };
    this.navigate = this.navigate.bind(this);
  }

  handleScan = data => {
    if (data) {
      const url = data.split('//')[1];
      const id = url.split('/')[1];
      const name = url.split('/')[2];

      this.setState({
        result: id,
        name
      });
      if (url.split('/')[3]) {
        this.setState({ server: url.split('/')[3] });
      }
      this.navigate();
    }
  };
  handleError = err => {
    console.error(err);
  };

  navigate() {
    const name = this.state.name.replace(' ', '&');
    this.state.server
      ? (window.location.href = window.location.href + this.state.result + '/' + name + '/' + this.state.server)
      : (window.location.href =
          window.location.href + this.state.result + '/' + name + '/' + process.env.REACT_APP_SERVER);
  }

  render() {
    return (
      <div className="qrReader">
        <QrReader
          delay={300}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ width: '100%' }}
          className="testee"
        />
      </div>
    );
  }
}
export default QrCamera;
