import React, { Component } from 'react';
import { emitDirection } from './api';
import arrowUp from './img/keyboard-arrow-up-black-48-dp.svg';
import arrowLeft from './img/keyboard-arrow-left-black-48-dp.svg';
import arrowRight from './img/keyboard-arrow-right-black-48-dp.svg';
import arrowDown from './img/keyboard-arrow-down-black-48-dp.svg';
import undoBack from './img/undo-black-36-dp.svg';
import shape from './img/path-4.svg';
import languages from './languages.json';

class Setas extends Component {
  render() {
    const idioma = this.props.entry;
    return (
      <div className="container3">
        <div className="setas">
          <div className="cima">
            <div className="icons2">
              <div className="icon-3">
                <img
                  alt=""
                  src={arrowUp}
                  className="keyboard_arrow_up-black-48dp"
                  onClick={() => emitDirection('up')}
                />
              </div>
            </div>
          </div>

          <div className="lados">
            <div className="icons">
              <div className="icon-2">
                <img
                  alt=""
                  src={arrowLeft}
                  className="keyboard_arrow_left-black-48dp"
                  onClick={() => emitDirection('left')}
                />
              </div>
            </div>

            <div className="icons">
              <div className="icon-5">
                <button onClick={() => emitDirection('enter')} className="enter-button">
                  <div className="Shape2"></div>
                </button>
              </div>
            </div>
            <div className="concave">
              <img alt="" src={shape} className="concave33" />
            </div>
            <div className="icons">
              <div className="icon-1">
                <img
                  alt=""
                  src={arrowRight}
                  className="keyboard_arrow_left-black-48dp"
                  onClick={() => emitDirection('right')}
                />
              </div>
            </div>
          </div>
          <div className="baixo">
            <div className="icons2">
              <div className="icon-4">
                <img
                  alt=""
                  src={arrowDown}
                  className="keyboard_arrow_up-black-48dp"
                  onClick={() => emitDirection('down')}
                />
              </div>
            </div>
          </div>
          <div className="SetasButtons">
            <div className="BackButton" onClick={() => emitDirection('back')}>
              <div className="leftMiddle">
                <img alt="" src={undoBack} className="undo-black-36dp" />
              </div>
              <div className="rightMiddle">
                <span>BACK</span>
              </div>
            </div>
            <div className="ForwardButton" onClick={() => emitDirection('forward')}>
              <div className="leftMiddle2">
                <span>FORWARD</span>
              </div>
              <div className="rightMiddle2">
                <img alt="" src={undoBack} className="undo-black-36dp2" />
              </div>
            </div>
          </div>
        </div>
        <div className="textarea">
          {languages['fraseController1'][idioma]}{' '}
          <span className="text-style-1">{languages['controller'][idioma]}</span> {languages['fraseTouchPad2'][idioma]}{' '}
          <br></br>
          {languages['fraseTouchPad3'][idioma]}
        </div>
      </div>
    );
  }
}

export default Setas;
