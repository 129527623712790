import React, { Component, useState } from 'react';
import QrCamera from './QrCamera';
import './InitialPage.css';
import logo from './img/asset-8-3-x.png';
import logo2 from './img/group-3.png';
import Button from 'react-bootstrap/Button';
import qrShape from './img/combined-shape2.svg';
import Modal from 'react-bootstrap/Modal';

class Initialpage extends Component {
  constructor() {
    super();

    this.state = {};
  }

  Example() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
      <>
        <div className="logo3">
          <Button onClick={handleShow} className="rectangle-button">
            <span>
              <img alt="" src={qrShape} className="Group" />
            </span>
            <div className="button-text">READ QR CODE</div>
          </Button>
        </div>

        <Modal
          dialogClassName="modal-content2"
          show={show}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <QrCamera />
          </Modal.Body>
        </Modal>
      </>
    );
  }

  render() {
    return (
      <div className="initialBody">
        <div className="logoBox">
          <div className="logo">
            <img alt="" src={logo} className="Asset-83x" />
          </div>
        </div>
        <div className="logoBox2">
          <div className="logo2">
            <img alt="" src={logo2} className="Group-3" />
          </div>
        </div>
        <div className="textarea">Read the QR code on the device to access the controller</div>
        <div className="add-QR">
          <this.Example />
        </div>
      </div>
    );
  }
}

export default Initialpage;
