import openSocket from 'socket.io-client';

let socket;
let roomS;

async function connectSocket(server, room) {
  const url = server;
  socket = openSocket(url + '?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9');

  socket.emit('joinRoom', room);
  roomS = room;

  socket.on('disconnect', () => {
    console.log('disconnect close');
  });
}

function emitDirection(direction) {
  socket.emit('remote.control', { key: direction, room: roomS });
}

// deprecated
function emitMouseNormalizedDistance(x, y) {
  socket.emit('mouse.control.distance', { x, y });
}

function emitMouseMovementStart(x, y) {
  socket.emit('mouse.control.start', { x, y, room: roomS });
}

function emitMouseOnMovement(x, y) {
  socket.emit('mouse.control.onMovement', { x, y, room: roomS });
}

function emitMouseMovementEnd(x, y) {
  socket.emit('mouse.control.end', { x, y, room: roomS });
}

// deprecated
function emitScrollDirection(direction) {
  socket.emit('mouse.scroll.direction', { direction });
}

function emitScrollDistance(x, y) {
  socket.emit('mouse.scroll.distance', { x, y });
}

function emitMupiButtonAction(button) {
  socket.emit('remote.button.pressed', { button });
}

export {
  connectSocket,
  emitDirection,
  emitMouseNormalizedDistance,
  emitScrollDirection,
  emitScrollDistance,
  emitMupiButtonAction,
  emitMouseMovementStart,
  emitMouseOnMovement,
  emitMouseMovementEnd
};
