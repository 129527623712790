import React, { Component } from 'react';
import Mupi from './Mupi';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const socketId = this.props.match.params.url;
    const name = this.props.match.params.name;
    const server = this.props.match.params.server;
    return (
      <div className="">
        <section className="results--section">
          <div className="container2">
            <Mupi name={name} socketId={socketId} server={server} />
          </div>
          <div className="results--section__inner"></div>
        </section>
      </div>
    );
  }
}

export default App;
