import React, { Component } from 'react';
import {
  emitScrollDirection,
  emitScrollDistance,
  emitMouseMovementStart,
  emitMouseMovementEnd,
  emitMouseOnMovement,
  emitDirection
} from './api';
import upperArrow from './img/upper-arrow.svg';
import bottomArrow from './img/bottom-arrow.svg';
import arrowLeft from './img/arrow-left.svg';
import arrowRight from './img/arrow-right.svg';
import languages from './languages.json';

class MouseTracker extends Component {
  constructor(props) {
    super(props);
    this.state = { finalX: 0, finalY: 0, initialX: 0, initialY: 0, maxHeight: 0, maxWidth: 0 };
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
  }

  isTwoFinger = false;

  componentDidMount() {
    const touchPad = document.getElementById('TouchPad');
    const maxHeight = touchPad.clientHeight;
    const maxWidth = touchPad.clientWidth;
    this.setState({ maxHeight, maxWidth });
  }

  onTouchMove(e) {
    if (e.target.className === 'TouchPad') {
      if (e.touches.length === 2) {
        this.isTwoFinger = true;
        const rect = e.target.getBoundingClientRect();
        const x = e.nativeEvent.touches[0].clientX - rect.left;
        const y = e.nativeEvent.touches[0].clientY - rect.top;
        this.setState({ finalX: Math.floor(x), finalY: Math.floor(y) });
      } else if (e.touches.length === 1) {
        const rect = e.target.getBoundingClientRect();
        const x = e.nativeEvent.touches[0].clientX - rect.left;
        const y = e.nativeEvent.touches[0].clientY - rect.top;
        const distX = Math.floor(x) - this.state.initialX;
        const distY = Math.floor(y) - this.state.initialY;
        emitMouseOnMovement((this.normalizedX(distX) * 1.5).toFixed(2), (this.normalizedY(distY) * 1.5).toFixed(2));
        this.setState({ finalX: Math.floor(x), finalY: Math.floor(y) });
      }
    }
  }

  normalizedX(x) {
    const maxX = this.state.maxWidth;
    const result = x / maxX;
    return result.toFixed(2);
  }

  normalizedY(y) {
    const maxY = this.state.maxHeight;
    const result = y / maxY;
    return result.toFixed(2);
  }

  changeStyles = how => {
    const bodyElt = document.querySelector('body');
    bodyElt.style.overflow = how;
  };

  onTouchStart(e) {
    this.changeStyles('hidden');
    if (e.target.className !== 'EnterButton') {
      const rect = e.target.getBoundingClientRect();
      const x = e.nativeEvent.touches[0].clientX - rect.left;
      const y = e.nativeEvent.touches[0].clientY - rect.top;
      this.setState({ initialX: Math.floor(x), initialY: Math.floor(y) });

      // fix one touch semi fix
      this.setState({ finalX: Math.floor(x), finalY: Math.floor(y) });
      emitMouseMovementStart(this.normalizedX(x), this.normalizedY(y));
    }
  }
  onTouchEnd(e) {
    this.changeStyles('unset');
    if (e.target.className !== 'EnterButton') {
      if (this.isTwoFinger) {
        this.isTwoFinger = false;
        this.scrollDistance();
      } else {
        emitMouseMovementEnd(
          this.normalizedX((this.state.finalX - this.state.initialX) * 1.5),
          this.normalizedY((this.state.finalY - this.state.initialY) * 1.5)
        );
      }
    }
  }

  scrollDirection() {
    const initialX = this.state.initialX;
    const finalX = this.state.finalX;
    const initialY = this.state.initialY;
    const finalY = this.state.finalY;

    const xDif = finalX - initialX;
    const yDif = finalY - initialY;

    if (Math.abs(xDif) > Math.abs(yDif)) {
      if (initialX > finalX) {
        emitScrollDirection('left');
      } else {
        emitScrollDirection('right');
      }
    } else {
      if (initialY > finalY) {
        emitScrollDirection('up');
      } else {
        emitScrollDirection('down');
      }
    }
  }

  scrollDistance() {
    const maxX = this.state.maxWidth;
    const maxY = this.state.maxHeight;
    const distX = this.state.finalX - this.state.initialX;
    const distY = this.state.finalY - this.state.initialY;
    const normalizedDistX = distX / maxX;
    const normalizedDistY = distY / maxY;
    emitScrollDistance(normalizedDistX.toFixed(2), normalizedDistY.toFixed(2));
  }

  render() {
    const idioma = this.props.entry;
    return (
      <div className="container3">
        <div
          id="TouchPad"
          className="TouchPad"
          onTouchMove={this.onTouchMove}
          onTouchStart={this.onTouchStart}
          onTouchEnd={this.onTouchEnd}
          onTouchCancel={this.onTouchCancel}
        >
          <div className="upper-arrow">
            <div className="upper-arrow-img">
              <img alt="" src={upperArrow} />
              <img alt="" src={upperArrow} />
            </div>
          </div>
          <div className="left-arrow">
            <div className="left-arrow-img">
              <img alt="" src={arrowLeft} />
              <img alt="" src={arrowLeft} />
            </div>
          </div>
          <div className="right-arrow">
            <div className="left-arrow-img">
              <img alt="" src={arrowRight} />
              <img alt="" src={arrowRight} />
            </div>
          </div>

          <div className="SetasButtons2">
            <div className="bottom-arrow">
              <div className="upper-arrow-img">
                <img alt="" src={bottomArrow} />
                <img alt="" src={bottomArrow} />
              </div>
            </div>

            <div className="EnterButton" onClick={() => emitDirection('enter')}>
              {languages['selecionar'][idioma]}
            </div>
          </div>
        </div>

        <div className="textarea">
          {languages['fraseTouchPad1'][idioma]} <span className="text-style-1">touchpad</span>{' '}
          {languages['fraseTouchPad2'][idioma]} <br></br>
          {languages['fraseTouchPad3'][idioma]}
        </div>
      </div>
    );
  }
}
export default MouseTracker;
