import React, { Component } from 'react';
import { connectSocket } from './api';
import Setas from './Setas';
import MouseTracker from './MouseTracker';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import shape from './img/shape.svg';
import combinedShape from './img/combined-shape.svg';
import languages from './languages.json';
import unitedKingdom from './img/united-kingdom.svg';
import portugal from './img/portugal.svg';
import france from './img/france.svg';
import italy from './img/italy.svg';
import spain from './img/spain.svg';
import fullscreen from './img/fullscreen-white-24dp.svg';
import fullscreenExit from './img/fullscreen_exit-white-24dp.svg';

class Mupi extends Component {
  constructor(props) {
    super(props);

    this.state = { mupiName: '', buttons: [], idioma: 'en', image: '', isFullscreen: false };
    this.ControlledTabs = this.ControlledTabs.bind(this);
    this.fullscreen = this.fullscreen.bind(this);
    this.exitfullscreen = this.exitfullscreen.bind(this);
  }

  async setupButtons() {
    let socketId;
    let name;
    this.setState({ image: unitedKingdom });
    socketId = this.props.socketId;
    const server = this.props.server;
    name = this.props.name.replace(/&/g, ' ');
    localStorage.setItem('@mupi-control/mupi-socket-id/', socketId);
    localStorage.setItem('@mupi-control/mupi-name/', name);
    this.setState({ mupiName: name });

    if (socketId) {
      await connectSocket(server, socketId);
    }
  }

  componentDidMount() {
    this.setupButtons();
  }

  fullscreen() {
    document.documentElement.requestFullscreen();
    this.setState({ isFullscreen: true });
  }

  exitfullscreen() {
    if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    this.setState({ isFullscreen: false });
  }

  ControlledTabs() {
    const idioma = this.state.idioma;
    return (
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="MouseEvent">
                  <MouseTracker entry={idioma} />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <Setas entry={idioma} />
              </Tab.Pane>
            </Tab.Content>
          </Col>
          <Col sm={9}>
            <Nav variant="pills" className="navbar">
              <Nav.Item>
                <Nav.Link eventKey="first">
                  <img alt="" src={shape} className="Shape" />
                  TOUCHPAD
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">
                  <img alt="" src={combinedShape} className="Shape" />
                  CONTROLLER
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
      </Tab.Container>
    );
  }

  render() {
    const idioma = this.state.idioma;
    const image = this.state.image;
    const isFullscreen = this.state.isFullscreen;
    return (
      <div className="mupi--section container">
        <div className="mupi-data">
          <div className="mupi-data-text">
            <p>{languages['nome'][idioma]}</p>
            <h1>{this.state.mupiName}</h1>
          </div>

          <div className="rectangle">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img alt="" src={image} className="undo-black-36dp" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => this.setState({ idioma: 'en', image: unitedKingdom })}>
                  <img alt="" src={unitedKingdom} className="undo-black-36dp" />
                </Dropdown.Item>
                <Dropdown.Item onClick={() => this.setState({ idioma: 'pt', image: portugal })}>
                  <img alt="" src={portugal} className="undo-black-36dp" />
                </Dropdown.Item>
                <Dropdown.Item onClick={() => this.setState({ idioma: 'fr', image: france })}>
                  <img alt="" src={france} className="undo-black-36dp" />
                </Dropdown.Item>
                <Dropdown.Item onClick={() => this.setState({ idioma: 'es', image: spain })}>
                  <img alt="" src={spain} className="undo-black-36dp" />
                </Dropdown.Item>
                <Dropdown.Item onClick={() => this.setState({ idioma: 'it', image: italy })}>
                  <img alt="" src={italy} className="undo-black-36dp" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="rectangle">
            {isFullscreen ? (
              <button id="dropdown-basic" className="dropdown-toggle btn btn-success" onClick={this.exitfullscreen}>
                <img alt="" src={fullscreenExit} className="undo-black-36dp" />
              </button>
            ) : (
              <button id="dropdown-basic" className="dropdown-toggle btn btn-success" onClick={this.fullscreen}>
                <img alt="" src={fullscreen} className="undo-black-36dp" />
              </button>
            )}
          </div>
        </div>
        <MouseTracker entry={idioma} />
      </div>
    );
  }
}

export default Mupi;
